import React from 'react';
import Particles from 'react-particles-js';
import ProfileConfig from './config/profile-config';



function ProfileBackground() {
    return (
            
        <Particles params={ProfileConfig}>
            
        </Particles>

    )
}

export default ProfileBackground


